.modal {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-top: 3.6875rem;
    padding-left: 31rem;
    padding-right: 31rem;
    background-color: rgba(10, 22, 50, 0.25);;
    border-radius: 4px;
    // left: calc(50% - 100px);
    width: 100vw;
    height: 100vh;

    .modal-content {
        background-color: #fff;
    }
}

// .editmodal {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
    
//     background-color: rgba(10, 22, 50, 0.25);;
//     border-radius: 4px;
//     // left: calc(50% - 100px);
//     width: 100vw;
//     height: 100vh;

//     .editmodal-content {
//         background-color: #fff;
//     }
// }

// .modal-content {
//     background-color: #fff;
// }