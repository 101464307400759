.homepage {
  font-family: "Avenir-Medium", sans-serif !important;
  background: #F8F9FA;

  // font-size: 1.2em;
  p {
    font-size: 1.2em;
    @include mobile(){
      font-size: 1.1em;
    }
  }

  .x-banner {
    background: url('https://byzpal.nyc3.cdn.digitaloceanspaces.com/images%2FRectangle%2039582.png') no-repeat;
    background-position: right;
    background-size: contain;
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
      padding: 4em 0em
    }
    @include mobile() {
      background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
        url("https://byzpal.nyc3.cdn.digitaloceanspaces.com/images%2FRectangle%2039582.png") no-repeat;
      background-size: 200%;
      background-position: center;
    }

    .x-left-grid {
      display: flex;
      flex-direction: column;
      padding: 2em 5em;
      // align-items: center;
      height: 100%;
      min-height: 500px;
      width: 100%;
      justify-content: center;

      @include mobile() {
        padding: 2em;
      }

      h1 {
        font-size: 4em;
        margin: 0 0 .5em 0;
        line-height: 89px;
        letter-spacing: 0.15754134953022003px;
        color: $blue;
        font-family: "Avenir-Bold", sans-serif !important;
     
        
        @include mobile() {
          font-size: 2.5em;
          line-height: 40px;
        }
      }

      p {

        // font-size: 1.2em;
        @include mobile() {
          color: #fff;
        }
      }

      button {
        padding-right: 2em;
        padding-left: 2em;
        background-color: $blue;
        color: #fff;
        border: unset;
        @include mobile(){
          font-size: 1em;
        }
      }
    }
  }

  .x-trustedby {
    background: #fff;
    margin: 0 5em;
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
    }
    @include mobile() {
      margin: 0;
    }

    .x-thumb {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2em;
      height: 100px;

      img {
        width: 4em;
      }
    }
  }

  .x-us {
    background: #fff;
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
    }
    // font-size: 1.1em;
    .x-left {
      padding: 4em 5em;

      @include mobile() {
        padding: 2em 2em 0;
      }

      h3 {
        color: $blue;
        font-weight: bolder;
        font-size: 1.4em;

        @include mobile() {
          font-size: 1.2em;
        }
      }

      p {
        font-size: 1.3em;
        font-weight: bolder;
        color: #152C5B;

        @include mobile() {
          font-size: 1.1em;
        }
      }
    }

    .x-right {
      padding: 4em 5em;

      @include mobile() {
        padding: 0 2em;
      }

      color: #152C5B;
      // font-size: 1.1em;
    }
  }

  .x-services {
    color: #152C5B;
    background: #fff;
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
    }
    a {
      font-size: 1.2em;
      color: $blue;
      @include mobile(){
        font-size: 1.1em;
      }
    }

    h2 {
      font-size: 2em;
      font-weight: bolder;
      color: $blue;
      @include mobile(){
        font-size: 1.3em;
      }

    }

    img {
      width: 80%;
      // align-items: flex-end;
    }
    .xpad{
      padding: 2em 5em;
      @include mobile(){
      padding: 2em;
      }
     }
    .x-left {
      // padding: 2em 0em;

      @include mobile() {}
    }

    .x-right {
      display: flex;
      padding: 2em 0em;

      &.flexend {
        justify-content: flex-end;
      }
    }
  }
  .x-download{
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
    }
    .x-left-grid{
      display: flex;
      flex-direction: column;
      padding: 2em 5em;
      // align-items: center;
      height: 100%;
      min-height: 500px;
      width: 100%;
      justify-content: center;
      @include mobile(){
        padding: 5em 2em 2em;
        min-height: unset;
      }
      .colored{
        color: $blue;
        font-weight: bolder;
        margin: 0;
      }
      h2{
        line-height: 40px;
        font-family: "Avenir-Bold", sans-serif !important;
        font-size: 2em;
        margin: 0 0 .5em;
      }
      img{
        width: 8em;
        margin-right: .5em;
        margin-top: 1em;
      }
    }
    .x-right-grid{
      img{
        width: 100%;
      }
    }
  }
}