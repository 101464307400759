.buttons-field {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: flex-start;
        
    .not-assigned {       
        align-items: center;
        border-radius: 20.281px;
        background: var(--orange-light, #FDEEEA);
        color: var(--alert-error, #CC293C);
        font-size: 0.69144rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 500;
        line-height: 1.15238rem;
        border: none;        
    }
    
    .test {
        align-items: center;
        gap: 0.57619rem;
        border-radius: 20.281px;
        background: #FFF5DA;
        color: var(--alert-warning, #D9A033);
        font-size: 0.69144rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 500;
        line-height: 1.15238rem;
        border: none;        
    }
}

.all-buttons {
    display: flex;
    gap: .52375rem;

    .button-2 {
        display: flex;
        gap: 0.306875rem;
        align-items: center;
        background-color: #F4A62A;
        color: #fff;
        font-size: .4125rem;
        border-radius: .250625rem;
    }

    .button-3 {
        display: flex;
        gap: 0.355625rem;
        align-items: center;
        background-color: #1DB88E;
        color: #fff;
        font-size: .415rem;
        border-radius: .250625rem;
    }

    .button-4 {
        display: flex;
        gap: 0.355625rem;
        align-items: center;
        background-color: #FA1212;
        color: #fff;
        font-size: .415rem;
        border-radius: .250625rem;
    }
}



.subadmin-style .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
  }
  .subadmin-style  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
  }

  .subadmin-style .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.22125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subadmin-style .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

.subadmin-select .ant-select-selection-placeholder {
    color: #9FA0BF;
    font-family: 'Avenir', sans-serif !important;
    font-size: 0.81419rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .subadmin-style {
    
    .subadmin-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .subadmin-input {
        color: #182337;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }

    .subadmin-inputDis {
        color: #B8B8B8;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }
    .subadmin-password-input ::placeholder {
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #5D6D73;
    }

    .subadmin-password-input .ant-input-prefix {
        color: transparent;
    }
    .subadmin-password-input .ant-input-prefix > span {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('') no-repeat center center;
        background-size: contain;
    }
    .subadmin-password-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        
    }
}




.cols-row {
    display: flex;
    flex-direction: row;

    .top-col {
        margin-bottom: .82rem;

    .header_andTwo {
        display: flex;
        justify-content: space-between;
        color: #182337;
        font-size: 1.5rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 600;
        @include mobile() {
            margin-top: 1.5rem;
        }
        @include miniTablet() {
            flex-direction: column;
        }
     
        .sub-header {
            color: #182337;
            font-size: 1.5rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 600;
        }
        .two-buttons {
            display: flex;
            gap: 0.75rem;
            @include miniTablet() {
                margin-bottom: 1rem;
            }
            @include small2ndmobile() {
                flex-direction: column;
                align-items: flex-start;
            }
         
    
            .outliner, .primary {
                display: inline-flex;
                padding: 0.5625rem 2.375rem;
                align-items: center;
                gap: 0.625rem;
                border-radius: .25rem;
                font-family: 'Avenir', sans-serif !important;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.375rem; /* 137.5% */
                letter-spacing: 0.01rem;
                @include small2ndmobile() {
                    font-size: .5rem;
                    padding: .2rem 1.2rem;
                }
                
            }
            .outliner {
                border: 1px solid #185CFE;
                background: #F7F8FA;
                color: #474646;
            }
            .primary {
                background: #185CFE;
                color:#FFFFFF  ;     
            }
        }
    }
    
}


    .h-header {
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8125rem;
    }

    .CustomStyler {
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.3125rem;
        @include tabler() {
            font-size: .62rem;
            line-height: .8125rem;
        }
        @include mobile() {
            font-size: .6rem;
            line-height: .8125rem;
        }
       }

       .ant-table-thead .ant-table-cell {
        background-color: #FFF;
      }

      .rowClassName {
        color: #152C5B;
        font-size: .875rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 500;
        line-height: 1.05875rem;
        @include mobile() {
            font-size: .6rem;
        }
        
    }
    .sub-table {
        overflow-x: auto;
    }
    .sub-table .ant-table {
        @include mobile() {
            width: 100%;
        }
        @include smallmobile() {
            width: 100%;
            overflow-x: auto;

        }
       
    }
}

.submitBut {
    color: #FFF;
    font-size: 0.81419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.458rem 1.93369rem;
    align-items: center;
    gap: 0.50888rem;
    border-radius: 0.23331rem;
    background: var(--byzpal-blue, #185CFE);
}



