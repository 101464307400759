.h-remote {
    color: #182337;
    font-size: 1.5rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    margin-bottom: 0.49rem;
    @include miniTablet() {
        margin-top: 1.3rem;
    }
}

.pAndBut {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.42rem;
    @include miniTablet() {
        flex-direction: column;
        align-items: flex-start;
        gap: .8rem;
    }
    .p-remote {
        color: #152C5B;
        font-size: 0.875rem;
        font-family: 'Avenir', sans-serif !important;
        line-height: 1.3125rem;
    }
    .createAgent {
        display: inline-flex;
        padding: 0.5625rem 2.375rem;
        align-items: center;
        gap: 0.625rem;
        border-radius: 4px;
        background: #185CFE;
        color: #FFF;
        font-size: 1rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 600;
        line-height: 1.375rem;
        letter-spacing: 0.01rem;
        @include small2ndmobile() {
            padding: .2rem 1rem;
            font-size: .5rem;
        }
    }
}


.remotestyles .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;

}

.remotestyles .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
}

.remotestyles .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.22125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.remotestyles .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

.remotestyles {
    .remote-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .remote-input {
        color: #182337;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }
}


   

    .carder {
        border: .1rem solid #E7E6F3;
        padding-right: 4.5rem;

				&.fair-padding {
					padding: 0.5rem;
					border-radius: 10px;
				}

        .p-borders {
            display: flex;
            width: fit-content;
            @include se2ndDesktop() {
                flex-direction: column;
            }

            .p-unknown, .p-waiting {
                border-right: .1rem solid rgb(21, 44, 91);
                opacity: 0.6000000238418579;
                color: #152C5B;
                font-size: 0.82rem;
                font-family: 'Avenir', sans-serif !important;
                font-weight: 500;
                @include se2ndDesktop() {
                    border: none;
                }
            }

            .p-unknown { 
                padding-right: .5rem;

                @include se2ndDesktop() {
                padding-right: 0rem;
            }    
            }
            .p-waiting {                
                padding: 0rem .5rem;
                 
                @include se2ndDesktop() {
                padding: 0rem;
            }
            }
        }
        .p-download {
            color: #185CFE;
            font-size: 0.87856rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 500;
            cursor: pointer;
        }

        .h-pc {
            color: #152C5B;
            font-size: 1.05425rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 500;
        }

        .testAndcode {
            color: #152C5B;
            font-size: 0.93713rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 500;
            opacity: 0.6000000238418579;
        }
    }


.submitBut {
    color: #FFF;
    font-size: 0.81419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.458rem 1.93369rem;
    align-items: center;
    gap: 0.50888rem;
    border-radius: 0.23331rem;
    background: var(--byzpal-blue, #185CFE);
}