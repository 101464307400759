.all-buttons {
    display: flex;
    // gap: .32375rem;


    
.button-1, .button-2, .button-3, .button-4 {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: .56475rem;
    border-radius: .250625rem;
    border: none;
    gap: .24rem;
   
}

.button-1 {
        
    background-color: #188FF7;
   
    
}
.button-2 {
        
        background-color: #F4A62A;
        
    }

.button-3 {
        
        background-color: #1DB88E;
        
    }

.button-4 {
        
        background-color: #FA1212;
        
    }
}



.vendor-style .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
  }
  .vendor-style  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
  }

  .vendor-style .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.22125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.vendor-style .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

.vendor-style {
    
    .vendor-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .vendor-input {
        color: #182337;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }

    .vendor-password-input ::placeholder {
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #5D6D73;
    }

    .vendor-password-input .ant-input-prefix {
        color: transparent;
    }
    .vendor-password-input .ant-input-prefix > span {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('') no-repeat center center;
        background-size: contain;
    }
    .vendor-password-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}



.for-cols {
    .h-vendor {
        color: #182337;
        font-size: 1.5rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 600;
        @include mobile() {
            margin-top: 1.5rem;
        }
    }
    
    .for-vendorSearch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.57rem;
        @include mobile() {
            flex-direction: column;
            align-items: flex-start;
        }
        .vendor-search {
            color: var(--dark-text, #5D6D73);
            font-size: 0.875rem;
            font-family: 'Avenir', sans-serif !important;
            line-height: 1.375rem;
            padding: .5rem 1.31rem;
            display: flex;
            max-width: 21.75rem;
            align-self:flex-start;
            border: .0625rem solid #E7E6F3;
        }
    
        .addVendor {
            display: inline-flex;
            padding: 0.5625rem 2.375rem;
            align-items: center;
            gap: 0.625rem;
            border-radius: 4px;
            background: #185CFE;
            color: #FFF;
            font-size: 1rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 600;
            line-height: 1.375rem;
            letter-spacing: 0.01rem;
            @include mobile() {
                margin-top: 1.2rem;
            }
            @include small2ndmobile() {
                padding: .2rem 1.2rem;
                font-size: .5rem;
            }
        }
    }

    
    .styler {
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.3125rem;
        @include tabler() {
            font-size: .62rem;
            line-height: .8125rem;
        }
        @include mobile() {
            font-size: .6rem;
            line-height: .8125rem;
        }
    }
    .ant-table-thead .ant-table-cell {
        background-color: #FFF;
      }

    .rowClassName {
        color: #152C5B;
        font-size: .875rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 500;
        line-height: 1.05875rem;
        @include mobile() {
            font-size: .6rem;
        }
        
    }

    .for-blue{
        background-color: #185CFE;
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 3rem;
        padding: 1rem;
        border-radius: .25rem;
        @include smallmobileA() {
            gap: .5rem;
            font-size: 1.5rem;
            padding: .7rem;
            
        }
    }
    
    .for-light{
        background-color: #188ff71a;
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 3rem;
        padding: 1rem;
        border-radius: .25rem;
        @include smallmobileA() {
            gap: .5rem;
            font-size: 1.5rem;
            padding: .7rem;
            
        }
    
    }

   .vendor-pagination {
    @include smallmobileA() {
        display: flex;
        justify-content: center; 
    }
    .pagination-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2.75rem;
        gap: 1.75rem;
        list-style: none;
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: .875rem;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.0125rem;
        width: auto;
    
        & li> a{
            color: #28293D;
        }

        @include smallmobileA() {
            justify-content: center;
            gap: .3rem;
            padding-right: 2.5rem;
        }
    }
   } 
    

    .vendor-table {
        overflow-x: auto;

    }
    
    .vendor-table .ant-table {
        @include mobile() {
            width: 100%;
        }
        @include smallmobile() {
            width: 100%;
            overflow-x: auto;

        }
       
    }

}

