.all-buttons {    
    max-width: fit-content;
    
    .select-1, .select-2 {
        color: #A1A3C1;
        font-size: 0.65169rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 500;
        line-height: 1.02413rem;
    }
    
    .select-2 {
        margin-left: 3rem;
        @include miniTablet() {
            margin-left: 1rem;
        }
    }
}



.resourceStyles .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
  }
  .resourceStyles  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
  }

  .resourceStyles .ant-modal-title {
      font-family: 'Avenir', sans-serif !important;
      color: #182337;
      font-size: 1.22125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
  }
 
  .resourceStyles .ant-modal-close-x {
        color: #182337;
        &:hover {
            opacity:.4;
        }  
}

.resource-select .ant-select-selection-placeholder {
    color: #9FA0BF;
    font-family: 'Avenir', sans-serif !important;
    font-size: 0.81419rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

.resourceStyles {
    
    .resource-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .resource-input {
        color: #182337;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }

}


.col_rows {
.header_andTwo {
    display: flex;
    gap: 23.26rem;
    align-items: center;

    @include mini-desktop() {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .5rem;
        margin-bottom: 1rem;
        
    }
    @include se2ndDesktop() {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .5rem;
        margin-bottom: 1rem;
    }

    .two_inputs {
        display: flex;
        gap: 0.94rem;
        @include mobile() {
            flex-direction: column;
            gap: 1rem;
            
        }

        .in-put, .in-put1 {
            border-radius: 4px;
            border: 1px solid var(--border, #E6F3EC);
            background: #FFF;
            padding: .5rem 1.31rem;
            color: var(--dark-text, #5D6D73);
            font-size: 0.875rem;
            font-family: 'Avenir', sans-serif !important;
            line-height: 1.375rem;
            
            &.in-put1 {
                max-width: 8.4375rem;
                flex-shrink: 0;
                @include mini-desktop() {
                    max-width: 20rem;
                }
                @include se2ndDesktop() {
                    max-width: 15rem;  

                }
            }
            &.in-put {
                max-width: 21.75rem;
                flex-shrink: 0;
                @include mini-desktop() {
                    max-width: 20rem;  
                }
                @include se2ndDesktop() {
                    max-width: 15rem;  

                }
            }
        }
    }

    .h-head {
        color: #182337;
        font-size: 1.5rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 600;
        @include mobile() {
            margin-top: 1rem;
        }
    }

}




.p-track {
    color: #152C5B;
    font-size: 0.875rem;
    font-family: 'Avenir', sans-serif !important;
    line-height: 1.3125rem;
}



.alternatings_andButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.94rem;
    @include mobile() {
        flex-direction: column;
        align-items: flex-start;
    }

    .alternatings {
        display: flex;
        gap: 2.8rem;
        @include small2ndmobile() {
            flex-direction: column;
            gap: 1rem;
        }

        .p-holder {
            
            width: auto;
            height: 2rem;
        }
        .p-maintenance, .p-supplies {
            color: #152C5B;
            font-size: 0.875rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 500;
            cursor: pointer;
            &:hover {
                border-bottom:0.24325rem solid #185CFE;
            }
            &:focus{
                border-bottom:0.24325rem solid #185CFE;
            }
            &:active {
                border-bottom:0.24325rem solid #185CFE;
            }
        }
    }

    .for-supplies, .for-ticket {
        display: inline-flex;
        padding: 0.5625rem 2.375rem;
        align-items: center;
        gap: 0.625rem;
        border-radius: 4px;
        background: #185CFE;
        color: #FFF;
        font-size: 1rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 600;
        line-height: 1.375rem;
        letter-spacing: 0.01rem;
        @include mobile() {
            margin-top: 1rem;
        }
        @include small2ndmobile() {
            padding: .2rem 1.2rem;
            font-size: .5rem;
        }
    }
}

.customa, .resouTab-1 {
    color: #5D6D73;
    font-family: 'Avenir', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.3125rem;
    @include tabler() {
        font-size: .62rem;
        line-height: .8125rem;
    }
    @include mobile() {
        font-size: .6rem;
        line-height: .8125rem;
    }
}

.ant-table-thead .ant-table-cell {
    background-color: #FFF;

  }

.rowClassName {
    color: #152C5B;
    font-size: 0.875rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 500;
    @include mobile() {
        font-size: .6rem;
    }
}


.for-blue{
    background-color: #185CFE;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 3rem;
    padding: 1rem;
    border-radius: .25rem;
    @include smallmobileA() {
        gap: .5rem;
        font-size: 1.5rem;
        padding: .7rem;
    }

}

.for-light{
    background-color: #188ff71a;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 3rem;
    padding: 1rem;
    border-radius: .25rem;
    @include smallmobileA() {
        gap: .5rem;
        font-size: 1.5rem;
        padding: .7rem;
    }

}

.resource-pagination {
    @include smallmobileA() {
        display: flex;
        justify-content: center; 
    }
    .pagination-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2.75rem;
        gap: 1.75rem;
        list-style: none;
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: .875rem;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.0125rem;
    
        & li> a{
            color: #28293D;
        }
        @include smallmobileA() {
            justify-content: center;
            gap: .3rem;
            padding-right: 2.5rem;
        }
    }
}


.resource-tableA, .resource-tableB {
    overflow-x: auto;
}


.resource-tableA .ant-table {
    @include mobile() {
        width: 100%;
    }
    @include smallmobile() {
        width: 100%;
        overflow-x: auto;

    }
   
}
.resource-tableB .ant-table {
    @include mobile() {
        width: 100%;
    }
    @include smallmobile() {
        width: 100%;
        overflow-x: auto;

    }
   
}
}



.tests, .appro {
    border-radius: 1.375rem;
    background: #FFF5DA;
    padding: 0.0625rem 0.95rem;
    gap: 0.625rem;
    color: var(--alert-warning, #D9A033);
    font-size: 0.75rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 500;
    line-height: 1.25rem;
    cursor: pointer;
}

.submitBut {
    color: #FFF;
    font-size: 0.81419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.458rem 1.93369rem;
    align-items: center;
    gap: 0.50888rem;
    border-radius: 0.23331rem;
    background: var(--byzpal-blue, #185CFE);
}