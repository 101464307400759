.x-billing {
  position: relative;
  width: 100%;
  background: rgb(247,250,252);
  position: absolute;
  width: 100%;
  height: 100%;
  .x-div {
      width: 100%;
      height: 100%;
      .ant-row{
      height: 100% !important;

      }
    .x-form {
        // margin-top: 10em;
      box-shadow: $box-shadow;
      border-radius: 10px;
      padding: 2em;
      margin: 2em;
      background: #fff;
      h2{
          margin-bottom: 1em; 
      }
     .x-submit{
         display: flex;
         align-items: center;
         padding-top: 1em;
         color: #555;
        button{
            padding-left: 2em;
            padding-right: 2em;
            margin: 0 1em 0 0
        }
        small{
            margin-top: .5em;
            color: #555;
            margin-left: .2em;
        }
     }
    }
  }
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
  background: #305d96 !important;
}
.x-paymentSuccessful{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  
}