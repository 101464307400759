.x-signage-playlist{  
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  .x-slick{
   
    // .slick-list{
   
        img{
          width: 100%;
          // height: 100%;
      
          // width: fit-content;
        }
      
    // }
  }
  // .slick-track{
  //   .slick-slide{
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     bottom: 0;
  //     right: 0;
  //     display: flex;
  
  //   img{
  //     width: 100%;
  
  //   }
  //   }
  // }
}