.serviceHolder {
    background-color: #F8F9FA;
    padding-top: 1.7325rem;

    .mainHolder {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        padding-top: 1.8125rem;       
        margin: 0rem 6.475rem 0rem 6.475rem;
        position: relative;
        z-index: 3;
        top: -.2rem;
        box-shadow: 0px 6px 40px rgba(52, 101, 166, 0.08)        
    }

    .rowSection {
        background-color: #E5E5E5;

        .serviceRow {           
            position: relative;
            background-color: #E5E5E5;
            z-index: 1;           
            top: -6rem;
            height: 22rem;
            padding: 0rem 6.25rem 0rem 7.6875rem;
            .col_head {
              padding-bottom: 2.5rem;
            }
            .serveHead {
                color: #185CFE;
                font-family: Poppins;
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1.8125rem;
                letter-spacing: 0.003257721rem;
                text-align: left;
                text-transform: uppercase;
            }
            .serve {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 1.75rem;
                line-height: 2.0625rem;
                color: #152C5B;           
            }

            .col_p {
                

                .p_serve {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.375rem;
                    line-height: 1.9375rem;
                    color: #152C5B;
                }
            }
            
        }
    }

    .spacing {
        padding-bottom: 7.1575rem;
        background-color: #E5E5E5;
    
        }
}


