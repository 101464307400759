.signage {
    background-color: #E5E5E5;
    margin-top: -2rem;

    .row-signage {
        padding-left: 7.8125rem;
        padding-right: 6.8125rem;
        .for_image {
            width: 100%;
            height: auto;
        }
        .col-row {
            display: flex;
            align-self: center;
        
            .h-digital {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 1.875rem;
                line-height: 2.1875rem;
                text-transform: capitalize;
                color: #185CFE;
            }
        
            .p-digital {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 1.25rem;
                line-height: 2rem;
                color: #152C5B;
                margin-top: 1.1875rem;
            }
        
            .t-holder {
                display: flex;
                align-items: center;
                gap: 0.875rem;
                margin-top: 2.545rem;


                .h-more {
                    font-family: 'Proxima Nova';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.23369375rem;
                    line-height: 1.5rem;
                    letter-spacing: 0.0440605625rem;
                    text-transform: capitalize;
                    color: #185CFE;
                }

                .icon-self {
                  max-width: 100%;
                  height: auto; 
                  margin-bottom: .4rem;
                }
            }
            .signage-div {
                padding-right: 2.82rem;
                .p-signage {
                    letter-spacing: .0008rem;
                    word-spacing: .004rem;
                }
            }
            .vendor-div {
                padding-left: 2.82rem;

                .p-vendor {
                    letter-spacing: .0008rem;
                    word-spacing: .004rem;
                }
            }
            .ticket-div {
                padding-right: 2.82rem;

                .p-ticket {
                    letter-spacing: .0008rem;
                    word-spacing: .004rem;
                }
            }
            .access-div {
                padding-left: 2.82rem;

                .p-access {
                    letter-spacing: .0008rem;
                    word-spacing: .004rem;
                }
            }
            
        
        }
    }

    .sinage_row {
        position: relative;
        margin-top: -1.1rem;
    }
    

   }