.leftTop {    
    padding: 8.4rem 0rem 0rem 6.5625rem;
    @include se2ndDesktop() {       
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.4rem 0rem 0rem 0rem;
    }
    @include small2ndmobile() {
        padding: 0rem;

    }
}
.image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    @include se2ndDesktop() {
        margin-top: 2.5rem;
    }
    .friendship {
        max-width: 100%;
        height: auto;
        
        // @include se2ndDesktop() {
        //     padding-left: 2rem;
        // }
        }
}

.greeting {
    color: #185CFE;
    font-size: 4.604375rem;
    font-weight: 700;
    line-height: 5.5625rem;
    @include se2ndDesktop() {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        line-height: 3rem;
    }
    @include small2ndmobile() {
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        line-height: 2rem;
    }
}
.explain {
    color: #000000;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 5.5625rem;
    @include se2ndDesktop() {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    @include small2ndmobile() {
        text-align: center;
        font-size: .7rem;
        font-weight: 400;
        line-height: .2rem;
    }
}
.butin-holder {
   
    @include tableter() {
        display: flex;
        // align-items: flex-end;
        // justify-content: flex-end;
        // text-align: right;
    }
    @include small2ndmobile() {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .butin {
        display: flex;
        gap: 0.625rem;
        align-items: center;
        justify-content: center;
        background-color: #185CFE;
        color: #FFFFFF;
        // box-sizing: border-box;
        border-radius: 31.25rem;
        height: 2.8125rem;
        padding: 0.875rem 1.625rem;
        // width: 10.0625rem;
        font-family: 'Proxima Nova';
        font-weight: 700;
        font-size: 0.875rem;
        margin-top: 1.8125rem;
        // flex-shrink: 0;
    
        .whiteArrow:hover {
            fill: #185CFE;
        }
        // .icoMargin:hover {
        //     color: #185CFE;
            
        // }
        // @include small2ndmobile() {
        //     height: 2rem;
        //     max-width: 7.0625rem;
        //     font-family: 'Proxima Nova';
        //     font-weight: 700;
        //     font-size: 0.675rem;
        //     margin-top: .5rem;
        // }
        
    }
}

