.for-overlay {
    z-index: 10;
    background-color: rgba(10, 22, 50, 0.25);;
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: 3.125rem;
    filter: blur(0.25rem);
}