.h-security {
    color: #182337;
    font-size: 1.5rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    margin-bottom: 0.49rem;
    @include miniTablet() {
        margin-top: 1.3rem;
    }
}
.security-tag {
    color: #D26B1F;
    font-size: 0.625rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 500;
    line-height: 104%;
    height: 1.125rem;
    padding: 0.2rem 0.375rem;
    border-radius: 4px;
    border: 1px solid #FED496;
    background: #FFF7E7;
    cursor: pointer;
    margin-bottom: .5rem;
    display: inline-flex;
    // align-items:flex-start;
    justify-content: space-between;
}
.but-role {
    color: #FFF;
    font-size: 0.66425rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 0.91331rem;
    letter-spacing: 0.00663rem;
    display: inline-flex;
    padding: 0.37363rem 0.74725rem;
    align-items: center;
    gap: 0.41513rem;
    border-radius: .1671875rem;
    background: #185CFE;
    
}


.securityStyles .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
  }
  .securityStyles  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
  }

  .securityStyles .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.22125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.securityStyles .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

.securityStyles {
    
    .security-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .security-input {
        color: #182337;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }
}

.rowCol {
.div-security {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.63rem;
    @include mobile() {
        margin: 1.5rem 0rem;
    }
    @include smallmobileA() {
        flex-direction: column;
        align-items: flex-start;
        margin: 1.5rem 0rem;
    }
    .security-but {
        display: inline-flex;
        padding: 0.5625rem 1.8125rem;
        align-items: center;
        gap: 0.625rem;
        border-radius: .25rem;
        background: #185CFE;
        color: #FFF;
        font-size: 1rem;
        font-family: 'Avenir', sans-serif !important;
        font-weight: 600;
        line-height: 1.375rem;
        letter-spacing: 0.01rem;
        @include small2ndmobile() {
            padding: .2rem .5rem;
            font-size: .5rem;
        }
    }
}

.customStyle {

    color: #5D6D73;
    font-family: 'Avenir', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.3125rem;
    @include tabler() {
        font-size: .62rem;
        line-height: .8125rem;
    }
    @include mobile() {
        font-size: .6rem;
        line-height: .8125rem;
    }
}

.ant-table-thead .ant-table-cell {
    background-color: #FFF;

  }
.rowClassName {
    color: #152C5B;
    font-size: .875rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 500;
    line-height: 1.05875rem;
    @include mobile() {
        font-size: .6rem;
    }
            
}
.for-blue{
    background-color: #185CFE;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 3rem;
    padding: 1rem;
    border-radius: .25rem;

    @include smallmobileA() {
        gap: .5rem;
        font-size: 1.5rem;
        padding: .7rem;
    }
}
.for-light{
    background-color: #188ff71a;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 3rem;
    padding: 1rem;
    border-radius: .25rem;
    @include smallmobileA() {
        gap: .5rem;
        font-size: 1.5rem;
        padding: .7rem;
    }
}

.security-pagination {
    @include smallmobileA() {
        display: flex;
        justify-content: center; 
    }
    .pagination-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2.75rem;
        gap: 1.75rem;
        list-style: none;
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: .875rem;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.0125rem;
    
        & li> a{
            color: #28293D;
        }
        @include smallmobileA() {
            justify-content: center;
            gap: .3rem;
            padding-right: 2.5rem;
        }
    } 
}

.security-table {
    overflow-x: auto;
}
.security-table .ant-table {
    @include mobile() {
        width: 100%;
    }
    @include smallmobile() {
        width: 100%;
        overflow-x: auto;

    }
   
}
}

.submitBut {
    color: #FFF;
    font-size: 0.81419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.458rem 1.93369rem;
    align-items: center;
    gap: 0.50888rem;
    border-radius: 0.23331rem;
    background: var(--byzpal-blue, #185CFE);
}
