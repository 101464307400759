.x-footer{
  padding: 3em 3em 0;
  background: #fff;
    border-top: 1px solid #eee;
    @include mobile(){
    padding: 2em 0em;
  }
  img{
    width: 6em;
    margin-bottom: 1.4em;
  }
  .x-container{
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
    }
  }
  .x-wrapper{
    
    // padding: 1em
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    h3{
        margin-top: 1em;
        @include mobile(){
            margin-bottom: 0;
        }
    }
    @include mobile(){
        // padding: 0;
    }
    a{
        margin: .7em 0 0em;
        display: block;
        color: #4F5362;
    }
  }
  .x-copyright{
    margin: 2em 3em 0;
    border-top: 1px solid #eee;
    padding: 2em;
    text-align: center;
    @include largedesktop{
      width: 85%;
      margin: auto;
      font-size: 1.3em;
    }
    @include mobile(){
        margin: 2em 0 0;
        padding: 1em 0 0em;
    }
    p{
        color: #4F5362;
        margin: 0;
        font-size: 1em;
    }
  }
}