.back {
  background-image: url("https://res.cloudinary.com/dzvhmiutm/image/upload/v1684421122/engine_m00xtz.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: relative;
  z-index: 1;

  .divForm {
    position: relative;
    z-index: 3;
    background-color: #ffffff;
    bottom: -4.70875rem;
    left: -6rem;
    padding-top: 3.906875rem;
    padding-bottom: 4.1675rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .back-form {
      display: flex;
      flex-direction: column;
      gap: 1.05986rem;
    }

    .h-message {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 1.42569375rem;
      line-height: 1.6875rem;
      text-align: center;
      letter-spacing: 0.00304865625rem;
      text-transform: uppercase;
      color: #185cfe;
    }

    .two-inputs {
      display: flex;
      gap: 1.09625rem;
      margin-top: 2.491875rem;
    }

    .submit-button {
      margin-top: 3.0325rem;
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: .8594875rem;
      line-height: 1.0625rem;
      letter-spacing: 0.0306960625rem;
      text-transform: capitalize;
      color: #ffffff;
      background: #185cfe;
      border-radius: 30.6960625rem;
      padding: 0rem 4.08875rem;
    }
  }
}

.top {
  width: 60%;
  padding: 8.4375rem 10.875rem 14.625rem 6.3125rem;
  position: relative;
  z-index: 1;

  .h-start {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.8125remm;
    letter-spacing: 0.003254775rem;
    text-transform: uppercase;
    color: #185cfe;
    padding-left: 1.5rem;
  }

  .h-comit {
    margin-top: 0.4175rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.0625rem;
    color: #152c5b;
    padding-left: 1.5rem;
  }

  .back-div {
    background-color: #185cfe;
    padding: 2.0625rem 1.875rem 2.125rem 1.5rem;

    .h-percent {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1.625rem;
      line-height: 2.0625rem;
      color: #ffffff;
    }

    .p-percent {
      margin-top: 0.375rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: .875rem;
      line-height: 1.5625rem;
      color: #ffffff;
    }
  }
}
