 .heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.9375rem;
        display: flex;
        align-self: center;
        text-align: center;
        color: #242424;
        width: fit-content;
    }
.holderDiv {
        display: flex;
        padding: 2em;
        height: 6.25rem;
        .for_res {
            max-width: 100%;
            height: auto;
        }
    }
    

