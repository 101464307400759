

@import "./font.scss";
@import "./mixins.scss";
// @import "./scss/style.scss";

body{
  background: #f7f8fa;
}
.content-wrapper{
  background-color: #f5f5f9 !important;
}


a {
  text-decoration: none;
}

.ant-picker {
  width: 100%;
  border-radius: 8px;
}

.some-custom-class {
  .slide-pane__content {
    padding-top: 3rem;
    padding-left: 1rem;

    ul {
      display: flex;
      padding-left: 0;
      list-style: none;
      flex-direction: column;

      li {
        margin-bottom: 1rem;

        a {
          color: $fontgrey;
          font-size: 16px;
        }

        .careers-btn {
          border-radius: 40px;
          background: $purple;
          color: $white;
          padding: 0.5rem 2rem 2.2rem;
          height: 2.5rem;

          &:hover,
          &:focus,
          &:active {
            outline: none !important;
            border-color: $white !important;
          }
        }
      }
    }
  }
}

.x-loader {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: #fff;

  svg {
    color: $purple !important;
  }

  &.inner {
    margin-top: 5rem;
  }
}

.calender-modal {
  .ant-modal-content {
    .ant-select-selector {
      height: initial !important;
      border-radius: initial !important;
      padding: 0rem 0.4rem !important;
    }
  }
}

.ant-modal-content {
  .ant-modal-title {
    font-family: "Avenir-Medium", sans-serif !important;
  }

  .ant-form-item-label {
    padding: 0;
  }

  input {
    height: 2.5rem;
    border-radius: 8px;
  }

  .password-input {
    height: 2.6rem;
    border-radius: 8px;

    input {
      height: 2rem;
      border-radius: unset;
    }
  }

  .switch-holder {
    display: flex;
    p {
      font-weight: 900;
      margin-right: 0.5rem;
    }
  }

  .ant-select-selector {
    height: unset !important;
    border-radius: 8px !important;
    padding: 0.2rem 11px !important;
  }
  .ant-form.label{
    font-size: unset !important
  }
  // .submit-btn {
  //   border-radius: 40px;
  //   background: $purple;
  //   color: $white;
  //   padding: 0.8rem 3rem 2.2rem;
  //   height: 2.5rem;
  //   outline: none !important;
  //   border-color: $white !important;

  //   &:hover,
  //   &:focus,
  //   &:active {
  //     outline: none !important;
  //     border-color: $white !important;
  //   }
  // }
}

// .ant-table-cell{
//   color: #566a7f !important;

//   .title{
//     color: #566a7f !important
//   }
// }
@import '../pages/dashboardnew/digital_signage/digital_signage.scss';
@import '../pages/dashboardnew/Resources/Supplies/supplies.scss';
@import '../pages/dashboardnew/Resources/Maintenance/maintenance.scss';
@import '../pages/dashboardnew/vendors/vendors.scss';
@import '../pages/dashboardnew/security/security.scss';
@import '../pages/dashboardnew/Romote/remote.scss';
@import '../pages/dashboardnew/SubAdmin/subAdmin.scss';
@import '../pages/dashboardnew/Resources/resources.scss';
@import '../components/UI/atoms/modalOverLay/modalOverLay.scss';
@import '../components/UI/atoms/button/Button.scss';
@import '../components/UI/molecules/Modal/modal.scss';
@import '../pages/dashboardnew/location/location.scss';
@import '../pages/dashboardnew/dashboardmain/infocard/infocard.scss';
@import '../pages/dashboardnew/dashboardmain/dashboardmain.scss';
@import '../pages/dashboardnew/dashboardnew.scss';
@import '../components/UI/molecules/contentTwo/contentTwo.scss';
@import '../components/UI/molecules/IconContent/iconContent.scss';
@import '../components/UI/molecules/TopContent/homeTop.scss';
@import "../components/UI/organisms/FormContent/background.scss";
@import "../components/UI/molecules/contentMobile/mobile.scss";
@import "../components/UI/molecules/Content/content.scss";
@import "../components/UI/molecules/navbar/naver.scss";
@import "../components/UI/organisms/footer/footer.scss";

@import "../components/nav/nav.scss";
@import "../components/nav/navmodal.scss";
@import "../components//careers/careerscard.scss";
@import "../components/uploadform/uploadform.scss";
@import "../components/dashboard/dashboardmain/dashboardmain.scss";
@import "../components/dashboard/dashboardmain/dashboardcards/dashboardcards.scss";
@import "../pages/dashboard/dashboard.scss";
@import "../components/dashboard/addjob/addjob.scss";
@import "../components/dashboard/jobs/jobs.scss";
@import "../components/dashboard/applications/applications.scss";
@import "../components/dashboard/users/users.scss";
@import "../components/dashboard/locations/locations.scss";
@import "../components/dashboard/inventory/inventory.scss";
@import "../components/resources/ticket.scss";
@import "../components/resources/resources.scss";
@import "../components/checks/index.scss";
@import "../components/checks/check.scss";
@import "../components/VendorDashboard/UploadInvoices.scss";
@import "../components/dashboard/locations/filter.scss";
@import "../components/signage/signage.scss";
@import "../components/footer/footer.scss";
@import "../components/nav/nav2nd.scss";
@import "../components/nav/hamburger.scss";

@import "../pages/signage/index.scss";
@import "../Layout/DashboardLayout/DashboardLayout.scss";
@import "../pages/homepage/homepage.scss";
@import "../pages/homepage/newHome.scss";
@import "../pages/careers/careers.scss";
@import "../pages/careerdetails/careerdetails.scss";
@import "../pages/about/index.scss";
@import "../pages/login/login.scss";
@import "../pages/billing/index.scss";
@import "../pages/signup/index.scss";

@import "../pages/dashboardnew/profile/profile.scss";