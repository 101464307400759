// responsiveness
@mixin desktop() {
  @media (min-width: 992px) {
    @content ;
  }
}

@mixin se2ndDesktop() {
  @media (max-width: 992px) {
    @content ;
  }
}

@mixin tableter() {
  @media (min-width: 768px) and (max-width: 992px) {
    @content ;
  }
}

@mixin tablet() {
  @media (min-width: 668px) and (max-width: 899px) {
    @content ;
  }
}

@mixin miniTablet() {
  @media (max-width: 615px) {
    @content ;
  }
}

@mixin mini-desktop() {
  @media (min-width: 1005px) and (max-width: 1251px) {
    @content ;
  }
}


@mixin mobile() {
  @media (max-width: 768px) {
    @content ;
  }
}


@mixin tabler() {
  @media (max-width: 1129px) {
    @content ;
  }
}

@mixin smallmobile() {
  @media (min-width: 375px) {
    @content ;
  }
}

@mixin smallmobileA() {
  @media (max-width: 479px) {
    @content ;
  }
}

@mixin small2ndmobile() {
  @media (max-width: 375px) {
    @content ;
  }
}

@mixin largedesktop() {
  @media (min-width: 1550px) {
    @content ;
  }
}
@mixin background($imgpath,$position:0 0,$repeat: no-repeat) {
  background: {
      image: url($imgpath);
      position: $position;
      repeat: $repeat;
  }
}
// colors
$white: #ffffff;
$lightblack: #182337;
$black: #000000;
$orange: #F2994A;
$blue: #185CFE;
$lightblue: #56CCF2;
$lightwhite: #E0E0E0;
$grey: #828282;
$lightgrey: #F2F2F2;
$lightergrey: #BDBDBD;
$coin: #FDBF00;
$dashboard: #F0FAFF;
$solidDash: #2D9CDB;
$disabled: #f5f5f5;
$red: #ec0000;
$fontgrey: #4F4F4F;
$purple: #0c59bd;
$titlePurple: #0a448f;
$fontgrey: #555;
$cardpurple: #f8f1f8;
$pagecolor: #f7f7f7;
$lighterwhite: #f6f6f8;
$borderColor:  #d9d9d9;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$antblue: #1890ff;

// shadow
$box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .16),
0 3px 6px 0 rgba(243, 234, 234, 0.12),
0 5px 12px 4px rgba(255, 241, 241, 0.09);

$extrabox-shadow: 0 1px 2px -2px rgba(255, 255, 255, 0.16), 0 3px 6px 0 rgba(255, 255, 255, 0.12), 0 5px 12px 4px rgba(145, 145, 145, 0.09);