.digital-header {
    display: block;
    font-family: 'Avenir', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8125rem;
}

.digital {  
    background-color: #fff;
    margin-top: 2rem;
    border-radius: .5rem;
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.87rem;
        padding: 0rem 2.4rem 10rem 2rem;
        border-right:  #E7E6F3 .0625rem solid;   
        margin-top: 2rem;
        
        
        .button-1, .button-2, .button-3 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            color:  #152C5B;          
            background-color: #fff;
            font-family: 'Avenir', sans-serif !important;
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.4rem;
            letter-spacing: 0.0125rem;
            border: 0.0625rem solid #CED1D5;
            border-radius: 1rem;
            padding: 1.19rem 0rem;
            box-sizing: border-box;
            text-align: center;
            width: 100%;
            &:hover {
                color: #fff;
                background-color: #185CFE;
            }
            &:active {
                color: #fff;
                background-color: #185CFE;
            }
            &:focus {
                color: #fff;
                background-color: #185CFE;
            }
        }    
        .button-one {
            color: #fff;
            background-color: #185CFE;
        }   
    }
}


// for the content component
.contents {
    display: flex;
    gap: 2rem;
    padding: 2rem 1rem 3rem 2rem;

    .content-buttons {
        display: flex;       
        justify-content: flex-end;
        gap: .994rem;

        .add-buttons-1, .content-buttons-2 {
            display: inline-flex;
            align-items: center;
            color: #185CFE;
            border: #185CFE 0.053rem solid;
            border-radius: .212rem;
            padding: 0.48rem 2.01rem;
            font-family: 'Avenir', sans-serif !important;
            font-size: 0.84788rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.16588rem;
            letter-spacing: 0.0085rem;
        }
    }

    .ant-collapse {
        .for-panel {
            .icon-trash {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #FFEBEB;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
            }
            .panel-holder-div {
                display: flex;
                align-items: flex-start;
                padding-left: 1.3rem;
                justify-content: space-between;
								width: 100%;
                
                .folder-icon-header {
                    display: flex;
                    align-items: flex-start;
                    gap: .5rem;

                    .panel-header {
                        color:  #152C5B;                      
                        font-family: 'Avenir', sans-serif !important;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        
                    }

                    .headers {
                        display: flex;
                        gap: .3rem;

                        .additional {
                            display: flex;
                            align-items: center;
                            color: #697A8D;
                            font-family: 'Avenir', sans-serif !important;                            
                            font-size: 0.65rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
                .firstDiv-trash {
                    padding-right: 2rem;
                }
                .button-and-trash {
                    display: flex;
                    gap: .5rem;
                    align-items: center;

                    .playButon {
                        display: flex;
                        gap: .2rem;
                        align-items: center;
                        background-color: #188FF7;
                        color: #FFF;
                        font-family: 'Avenir', sans-serif !important;
                        font-size: 0.46869rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.00938rem;
                    }
                }

            }           
        }
        // .ant-collapse-item {
        //     border-bottom: .0625rem solid #E7E6F3;
        //     width: 80%; 
        //     margin: 0 auto; 
           
        // }
        .ant-collapse-header {
            
            padding: 0rem 10.31rem 0rem 0rem; 
             
          }
        .ant-collapse-content-box {
            padding: 0rem 10.62rem 0rem 1.5rem; 
            
          }
   
    }

    .two-icons-holder {
        display: flex;
        align-items: center;
        gap: .5rem;
    .icon-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFEBEB;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
       
    }
}

    .holder-row {
        display: flex;       
        gap: 1.56rem;
        margin-bottom: .8rem;
        .holder-col {
            display: flex;            
            padding-left: 3.2rem;            
        
            .holder {
                display: flex;
                justify-content: space-between;
                width: 17.75rem;
               
            .duration-and-twoIcons {
                display: flex;
                gap: 1.12rem;
                
                .header-and-duration {
                    display: flex;
                    flex-direction: column;
    
                    .small-header {
                        cursor: pointer;
                        color:  #152C5B;                      
                        font-family: 'Avenir', sans-serif !important;
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
    
                    .for-two-header {
                        display: flex;
                        gap: .3rem;
                        .smaller-header {
                            color: #697A8D;
                            font-family: 'Avenir', sans-serif !important;
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }

            }
            .icon-and-title {
                display: flex;
                gap: .5rem;

                .small-header {
                    padding-top: .4rem;                  
                    color:  #152C5B;                      
                    font-family: 'Avenir', sans-serif !important;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            .trassh {
                margin-top: .3rem;
                cursor: pointer;
            }
        }           
        }
    }

.subadmin-style .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
  }

  .subadmin-style  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
  }

  .subadmin-style .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.22125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.subadmin-style .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

.subadmin-select .ant-select-selection-placeholder {
    color: #9FA0BF;
    font-family: 'Avenir', sans-serif !important;
    font-size: 0.81419rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  .subadmin-style {
    .to-add {
    }
    .subadmin-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .subadmin-input {
        color: #182337;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }

    .subadmin-inputDis {
        color: #B8B8B8;
        border-radius: 0.30531rem;
        border: .0763125rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }
    .subadmin-password-input ::placeholder {
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #5D6D73;
    }

    .subadmin-password-input .ant-input-prefix {
        color: transparent;
    }
    .subadmin-password-input .ant-input-prefix > span {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('') no-repeat center center;
        background-size: contain;
    }
    .subadmin-password-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        
    }
}
}

.submitBut {
    color: #FFF;
    font-size: 0.81419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.458rem 1.93369rem;
    align-items: center;
    gap: 0.50888rem;
    border-radius: 0.23331rem;
    background: var(--byzpal-blue, #185CFE);
}