.foota {
    position: relative;
    padding: 7.75rem  8.27625rem 1.125rem 9.3125rem;
    box-sizing: border-box;
   

  .footerSection { 
   

      .footer-row {
        border-bottom: .03rem solid #DDDEE6;
        padding-bottom: 5.09375rem;
        .doubleHolder {
            display: flex;
        }
        .p-step {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: .875rem;
          line-height: 1.5rem;
          margin-top: .8rem;
          word-spacing: .2rem;
          
        }
        .social {
          margin-top: .7rem;
          display: flex;
          align-items: center;
          gap: .583125rem;
          

          .social_icon:hover {
            opacity: .8;
          }
        }

        .col-links {
          position: relative;
          left: 3.05875rem;

          .h-links {
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }

        .h2d-links {
          // font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
        
    }

    .copyright { 
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.125rem;

        .p-copy {
          font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: .875rem;
line-height: 1.5rem;
        }
    }
    
   
   }

}


.sida {
    right: -6px;
    top: 0px;
    position: absolute;
    height: auto;
    max-width: 100%;
    
    
   }

   .changeColor {
    color: #4F5362;
    font-style: normal;
font-weight: 400;
font-size: .875rem;
line-height: 1.5rem;
   }
.middle {
  padding-top: .7rem;
  margin-left: 1.639378rem;
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.5rem;
}

.changeColor:hover {
  color: #abafbe;
}

