.dashboardmain-page {
	padding-top: 16px;
	
	.page-title {
		font-family: 'Avenir', sans-serif !important;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: #182337;
		margin-bottom: 8.16px;
	}

	.page-subtitle {
		color: #152c5b;
		font-family: 'Avenir', sans-serif !important;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 21.21px;
	}
}
