.infocard {
	background: #ffffff;
	border: 1px solid #e7e6f3;
	border-radius: 8px;
	padding: 24px 26.68px;
	margin-bottom: 32px;
	position: relative;

	.infocard-top {
		margin-bottom: 23.32px;
		display: flex;
		align-items: center;

		.img-holder {
			width: 28px;
			height: 28px;
			border-radius: 50%;
			margin-right: 8px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.infocard-value {
		font-family: 'Avenir', sans-serif !important;
		font-style: normal;
		font-weight: 600;
		font-size: 42px;
		line-height: 51px;
		color: #152c5b;
	}

	.backdrop-holder {
		position: absolute;
		bottom: 0;
		right: 32.49px;
	}
}
