.x-check-div{
// padding-top: 1em;
// border-top: 1px solid #000;
// border-top-style: dashed;
.x-dash-border{
    border-top: 1px solid #000;
    border-top-style: dashed;
    // margin-bottom: 1em;
}
.xcheck{
    width: 100%;
    height: 3.5in;
    // background: url("https://res.cloudinary.com/afrikal/image/upload/v1636131399/docuguardbg_bacwqj.png");
    background-size: contain;
    background-position: center;
    // background-size: cover;
    display: flex;
    padding: 0em;
    // margin-bottom: 2em 0 0;
    width: 100%;
    .xborder{
        width: 100%;
        padding: 1em 2em;


    .x-title{
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;
        .x-left{
            font-size: 1.4em;
            font-weight: 500;
            div:nth-child(1){
                display: flex;
            align-items: center;
              
            img{
                width: 70px;
                height: 40px;
                margin-right: 1em;
             }
             div{
                 div:nth-child(2),div:nth-child(3),div:nth-child(4){
                    font-size: 0.7em;
                 }
             }
             }
        }
        .x-right{
            display: flex;
            // flex-direction: column;
            // width: 30%;
            justify-content: space-between;
           align-items: center;
           
        .x-bankdetails{
            margin-right: 2em;
            text-align: center;
            font-size: 1.4em;
            font-weight: 500;
                div:nth-child(2),div:nth-child(3),div:nth-child(4){
                   font-size: 0.7em;
                }
        }
         .x-date{
             display: flex;
             align-items: center;
             .x-underline{
                margin-left: 1em;
                border-bottom: 1px solid #7184a1;
                flex-grow: 1;
                font-size: 1.2em;
            }
        }
        }
    }
    .x-body{
        margin-top: 0.9em;
       .x-pay{
           display: flex;
        .x-left{
            display: flex;
            width: 80%;
           .x-order{
            width: 50%;
            border-bottom: 1px solid #7184a1;
            flex-grow: 1;
            align-items: center;
            display: flex;
            padding-left: 1.5em;
            font-size: 1.2em;
            text-transform: capitalize;
        }
        }
        .x-right{
            display: flex;
            width: 20%;
           h2{
            font-size: 2em;
            margin: 0 0.2em 0 1em
           }
           .x-amount{
            border-bottom: 1px solid #7184a1;
               flex-grow: 1;
               display: flex;
               align-items: center;
               padding-left: 1em;
               font-size: 1.2em;
             
           }
       }
    }
    .x-dollars{
        display: flex;
        align-items: center;
        margin-top: 1em;
        .x-amount{
            border-bottom: 1px solid #7184a1;
           width: 100%;
           margin-right: 1em;
           font-size: 1.2em;
           text-transform: capitalize;
        }
        h2{
            margin: 0;
        }
    }
    }
    }
    .x-memo{
        display: flex;
        margin-top: 0em;
     .x-left{
         display: flex;
         width: 70%;
         align-items: flex-end;
        .x-desc{
         width: 50%;
         border-bottom: 1px solid #7184a1;
         flex-grow: 1;
         padding-left: 1em;
           text-transform: capitalize;
           font-size: 1.2em;
     }
     }
     .x-right{
         display: flex;
         width: 30%;
         justify-content: flex-end;
        span{
         margin: 0 0.2em 0 1em
        }
        .x-signature{
            border-bottom: 1px solid #7184a1;
            flex-grow: 1;
            margin-left: 4em;
            justify-content: center;
            display: flex;
            height: 50px;
            img{
                // width: 300px;
                // height: 50px
                width: 200px;
                height: 50px;
                object-fit: contain;
                // margin-top: -40px
                ;
            }
        }
    }
    }
    .xborder2,.xborder3{
        width: 100%;
        padding:4em 2em 1em 8em;


    .x-title{
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;
        .x-left{
            font-size: 1.1em;
            font-weight: 500;
        }
        .x-right{
        h2{
            font-size: 1.1em;
        }
         .x-date{
             display: flex;
             align-items: center;
             .x-underline{
                margin-left: 1em;
                border-bottom: 1px solid #7184a1;
                flex-grow: 1;
                font-size: 1.1em;
            }
        }
        }
    }

    .x-body{
        .x-order{
            font-size: 1.5em;
            padding: 2em 0 0;
        }
        .x-amount{
            font-size: 1.5em;
        }
        .x-memo{
            font-size: 1.5em;
            padding: 2em 0 0;

        }
    }
    }

    &.x-gradient{
        // background: rgb(255,255,255);
        // background: linear-gradient( rgba(255,255,255,0.1), rgba(194, 202, 238, 0.9) );
        // margin-bottom: 1em;
    }
    &.x-no-bg{
        background: rgb(255,255,255);
    height: 4in;

    }
        // border-style: double;

 
}

.x-check-bottom{
    display: flex; 
    // justify-content: space-between;
    width: 100%;
    // padding-left: 10%;
    font-weight: 500;
    padding: 0.8em 0 0em;
    font-size: 1.2em;
    font-family: 'micr';
    justify-content: center;
    div{
        // font-size: 1.5em;
        padding: 0 0.2em;

        span{
            padding: 0 0.2em;
        font-size: 0.8em;
        }
    }
}
}  