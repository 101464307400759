.dashboard-layout {
  .ant-layout.ant-layout-has-sider {
    height: 100vh;
  }

  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .dashboard-content {
    margin: 24px 16px;
    padding: 0px 12px;
    // padding: 1em;
    overflow-y: scroll;
  }

  .dashboard-show {
    padding: 0.2rem 0 0.2rem;

    li {
      background: transparent !important;
      font-size: 15px;
    }
  }

  .trigger-icon {
    color: $black;
    font-weight: 900;
    font-size: 20px;
    padding-left: 1rem;
  }

  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dashboard-header-others {
      padding-right: 2rem;
      display: flex;
      align-items: center;

      .others-item {
        margin-right: 1.5rem;

        &.balance {
          margin-top: 0.9rem;
        }

        &:last-child {
          margin-right: 0rem;
        }
      }

      .noti-icon {
        font-size: 23px;
        font-weight: 900;
      }
    }
  }
}