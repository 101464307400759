.dashboardmain {
  .big-title {
    font-size: 30px;
    font-weight: 900;
    font-family: 'Avenir-Bold', sans-serif !important;
    margin-bottom: 0.5rem;
  }

  

  .content-to-below {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;

    p {
      font-size: 16px;
      font-family: 'Avenir-Medium', sans-serif !important;
    }

    
  }

}
.userDssh_header {
  color: $lightblack;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Inter';
  font-style: normal;
  line-height: 29px;
}

.greeting-p {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */


color: #152C5B;

}