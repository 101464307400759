.navbar {
  background: #fff;

  // @include mobile() {
  //   display: grid;
  //   gap: -3rem;
  //   padding: 1rem 4rem 1rem 3rem;

  // }

  .navbar-container {
    display: flex;
    // height: 4.5rem;
    // justify-content: space-between;
    align-items: center;
    padding: 1em 5em;
    @include largedesktop{
      width: 85%;
      margin: auto;
    }
    @include mobile(){
      padding: 2em;
    }
  }

  .hamburgerN {
    display: none;
    .hamburger{
      border: unset;
    }
    @include mobile() {
      position: absolute;
      display: flex;
      top: 1.7rem;
      right: 1.5rem;
      z-index: 10;
    }

  }

  .list_style_sm{
    display: none;
    &.active{
      display: flex;  
    }
    @include mobile(){ 
     margin: 0;
     padding: 0 2em;
      flex-direction: column;
    list-style: none;
    .listholders{
      .listItem{
        font-weight: normal;
        margin-bottom: 1em;
        display: block;
        color: #000000;

      }
    }
  }
  }
  .navlogo {
    width: 7rem;
    height: 2.359375rem;

    @include mobile() {
      width: 5rem;
      height: 1.5rem;
      // margin-top: 1.6rem;

    }

  }

  .list_style {
    width: fit-content;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 1.5625rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;

    @include mobile() {

      // display: inline;
      // flex-wrap: wrap;
      // gap: 4rem;
      &.lg {
        display: none;
      }


      padding-left: 4.5rem;
      padding-top: 1.5rem;
      height: 14rem;
      margin-top: 19.3rem;
      width: 15rem;
      margin-right: -4.9rem;
      background-color: rgba(232, 235, 239, 0.5);
    }

    .listholders {
      width: fit-content;
      &.right{
        margin-left: auto;
      }
    }

    .listItem {
      color: #000000;
      width: fit-content;
      // font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 700;
      font-size: .975rem;
      line-height: 1.25rem;
      // font-feature-settings: 'salt' on, 'liga' off;
    
      &:focus {
        color: #15518A;
      }
    
      &:hover {
        color: #15518A;
      }
    
      &:active {
        color: #15518A;
      }
    
      @include mobile() {
        margin-top: 1.5rem;
      }
    
    }
    
    .listItemH {
      width: fit-content;
      // font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 700;
      font-size: .975rem;
      line-height: 1.25rem;
      // font-feature-settings: 'salt' on, 'liga' off;
      color: #000000;
    
      &:focus {
        color: #15518A;
      }
    
      &:hover {
        color: #15518A;
      }
    
      &:active {
        color: #15518A;
      }
    
      @include mobile() {
        margin-bottom: 1.5rem;
      }
    }
  }
}




.butholders {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 
  border: none;
  background: $blue;
  border-radius: 20px;

  & :hover {
    opacity: .9;

  }

  .listInBut {
    padding: 0.38922375rem 2.794625rem;
    // font-family: 'Helvetica Neue';
    font-style: normal;
    // font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    // font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;

    & :hover {
      cursor: pointer;
    }
  }
}