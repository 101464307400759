.mobile {
  background-color: #ffffff;
  padding: 5.125rem 6.8125rem 3.875rem 7.8125rem;

  .monial-col {
    display: flex;
    flex-direction: column;

    .arrows-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mobile() {
        flex-direction: column;
        padding-top: 2.2rem;
        justify-content: space-evenly;
        align-items: center;
      }

      .backward {
        max-width: 100%;
        height: auto;
      }

      .forward {
        max-width: 100%;
        height: auto;
      }

      .arrow-p {
        margin-top: 2.25rem 0rem 2.4rem 0rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.1875rem;
        width: fit-content;
        text-align: center;
        color: #111827;
        padding: 0rem 8.5rem;

        @include mobile() {
          font-size: 1.2rem;
        }
        
      }
    }

    .test_head {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 1.375rem;
      line-height: 1.8175rem;
      letter-spacing: 0.003254775rem;
      text-transform: uppercase;
      color: #185cfe;
    }

    .c_head {
      margin-top: 0.30375rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.0625rem;
      display: flex;
      align-items: center;
      color: #152c5b;
    }

   
    .centerIcon {
      margin: 4.13375rem auto 0rem;
      display: block;
      max-width: 100%;
      height: auto;
    }
    .centerer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .named {
      margin-top: 1.125rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 1.5rem;
      text-align: center;
      font-feature-settings: "liga" off;
      color: #111827;
    }

    .post {
      margin-top: 0.25rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      font-feature-settings: "liga" off;
      color: #4b5563;
    }

    .imaged {
      margin-top: 2.265rem;
    }
  }
}
