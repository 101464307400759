.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.75rem  6.27625rem 2.3rem 6.3125rem;
    @include mobile() {
        padding: 1.75rem 2.5rem 1.5rem 2.5rem;
    }
    .logoAndMenu {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        .menuDiv {
            padding-top: .9rem;
            .linkItems {
                margin-left: 1.5625rem;
                color: #000;
                font-size: .875rem;
               
                @include mobile() {
                    display: none;
                  }
            }
            .colorChange {
              color: #185CFE;
            }
        }

    

    }

    .logSign {
        display: flex;
        align-items: center;
        .for_link {
            color: #000;
            font-weight: 700;
            font-family: Inter;
            font-size: 0.875rem;
        }
        .for_link:hover {
            color: #185CFE;
        }
        .D_login {
            padding-top: .5rem;
            @include mobile() {
                display: none;
              }
        }

        .navBut {
            

            margin-left: 1.125rem;
            border-radius: 2rem;
            background-color: #185CFE;
            color: #fff;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: .76550625rem;
            line-height: .9375rem;
            // opacity: .9;
            width: 96.58px;
            padding: .7rem 0 1.7rem 0;
            @include mobile() {
                display: none;
              }
        }
        
    }

    .hamburgerMenu {
        display: none;
        &.active{
            display: flex;
            }
        @include mobile() {
            
        }
    }

    .s_linkItems {
       
        
    }
    .hamburgHolder {
        display: none;

        @include mobile() {
            display: flex;
            flex-direction: column; 
          }
    }
}
