.profile {
	.alert-holder {
		margin-bottom: 1rem;
	}

	span.ant-input-password.ant-input-affix-wrapper,
	.ant-input-affix-wrapper {
		height: 2.8rem;
		border-radius: 8px;
	}
}

