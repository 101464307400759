.mobile-app {
    background-color: #F8F9FA;

    .col-m {
display: flex;
align-items: center;
padding-left: 7.8125rem;
padding-right: 4rem;

        .h-mobile {
            font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.0625rem;
    /* identical to box height, or 84% */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    
    color: #185CFE;
        }
    
        .h-download {
            font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.6875rem;
    /* or 120% */
    
    display: flex;
    align-items: center;
    
    color: #242424;
    margin-top: 0.4875rem;
        }
    
        .p-mobile {
            font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.0625rem;
    line-height: 1.6875rem;
    /* or 160% */
    
    display: flex;
    align-items: center;
    
    color: #4F5362;
    margin-top: 1.336875rem;
        }

        .div-stores {
            display: flex;
            gap: 0.804375rem;
            margin-top: 1.981875rem;
            @include mobile() {
                display: flex;
                flex-direction: column;
            }

            .socials {
                max-width: 100%;
                height: auto;
            }
        }
    }

    
    .col-hand {
        // display: flex;
        // justify-content: center;
        // padding-right: 1.47375rem;
        .hand-image {
            // padding-right: 1.47375rem;
            max-width: 100%;
            height: auto;
        // padding-top: -7rem;
        // color: #38b8ef;


        }
    }
   
}
// padding-left: 7.8125rem;
//         padding-right: 6.8125rem;