.x-signatures {
	.add-signature_upload {
		width: 50%;

		@include se2ndDesktop() {
			width: 50%;
		}
		@include small2ndmobile() {
			width: 100%;
		}
		@include mobile() {
			width: 100%;
		}
	}

	.section-top {
		display: flex;
		justify-content: space-between;
		.action-holder {
			display: flex;
			button {
				&:first-child {
					margin-right: 0.5rem;
				}
			}
		}
	}

	.title-head {
		font-size: 25px;
		font-family: "Avenir-Medium", sans-serif !important;
		align-items: center;
		display: flex;
		button {
			margin-right: 1em;
		}
	}
	// h1{
	//   font-size: 1.5em;
	// }
	.x-box {
		box-shadow: $extrabox-shadow;
		background: $white;
		cursor: pointer;
		margin: 2em;
		padding: 3em;
		justify-content: center;
		align-items: center;
		display: flex;
		font-weight: bold;
		flex-direction: column;
	}
	.x-item {
		box-shadow: $extrabox-shadow;
		margin: 1em 0;
		padding: 2em;
		img {
			width: 100%;
		}
		p {
			margin-left: 2em;
			margin-bottom: 0;
			span {
				text-transform: capitalize;
				font-weight: 500;
				font-size: 1.2em;
			}
		}
	}
}

.main-top-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;

	.main-top-header {
		p {
			margin-bottom: 0;
		}
	}
}

.main-middle-container {
	display: flex;
	justify-content: space-between;
	background: white;

	.main-middle-container-filter {
		display: flex;
		justify-content: space-between;
		margin-bottom: 3rem;

		.range-holder {
			margin: 0 1rem;
		}
	}
}
