.upload-invoices {
  .section-top {
    display: flex;
    justify-content: space-between;
    .action-holder {
      button {
        &:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .title-head {
    font-size: 25px;
    font-family: "Avenir-Medium", sans-serif !important;
    align-items: center;
    display: flex;
    button{
      margin-right: 1em;
    }
  }
  .submit-btn{
    margin-top: 2em;
    ;
  }
}

.main-holder{
  flex-direction: column;
  display: flex;
  padding-bottom: .2rem;
  // justify-content: center;
  background-color: #F5F6FA;
  border-radius: .40706rem;
  
  cursor: pointer;

  .holder-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 24.8rem;
		width: 100%;

    
    .the-uploader {
            color:#5D6D73;
            // border-radius: 0.30531rem;
            // border: .0763125rem solid #E7E6F3;
            font-family: 'Avenir', sans-serif !important;
            font-size: 0.81419rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
    }
  }
}

