.dashboardnew {
	background: #f7f8fa;
	margin: 0;
	// padding-bottom: 4rem;
	overflow-x: hidden;
	// overflow-y: hidden;

	.dashboardnew-nav {
		background: #ffffff;
		border: 1px solid #e6ebf3;
		padding: 21.33px 40.03px 22.48px;
		display: flex;
		align-items: center;

		.logo-holder {
			width: 18%;
		}

		.nav-actions {
			padding-left: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.search-holder {
				position: relative;

				input {
					width: 248px;
					background: #f5f6fa;
					border: 1px solid #f5f5f5;
					border-radius: 40px;
					box-sizing: border-box;
					outline: none;
					border: 0px;
					padding-left: 55.5px;
					padding-top: 10.78px;
					padding-bottom: 12.53px;

					&::placeholder {
						color: #9fa4bf;
						font-family: 'Avenir', sans-serif !important;
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
					}
				}

				.img-holder {
					position: absolute;
					top: 10px;
					left: 26.64px;
				}
			}

			.user-details {
				display: flex;
				align-items: center;

				.user-avatar {
					position: relative;

					.img-holder {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						margin-right: 13.05px;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.online {
						position: absolute;
						background: #71dd37;
						border-radius: 50%;
						width: 8px;
						height: 8px;
						box-shadow: 0px 0px 0px 2px #000000;
						right: 12px;
						bottom: -10px;
					}
				}

				.user-name {
					margin-bottom: 0;
					font-family: 'Avenir', sans-serif !important;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					color: #5d6d73;
				}
			}
		}
	}

	.dashboardnew-content {
		margin-top: 19px;

		.sidenav {			
			margin-left: 18px;
			background: #ffffff;
			border: 1px solid #e7e6f3;
			border-radius: 8px;
			padding-top: 27.69px;			

			.parent-nav {
				padding-left: 27.09px;
				font-family: 'Avenir', sans-serif !important;
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				color: #a09fbf;
				margin-bottom: 27.18px;
			}

			.nav-item {
				display: flex;
				// justify-content: center;
				// align-items: center;
				// margin-bottom: 30.82px;
				// gap: 6rem;
				padding-left: 1.6175rem;
				cursor: pointer;
				width: 100%;
				padding-bottom: 8px;
				@include tableter() {
					padding-left: .85rem;
				}

				span {
					color: #697a8d;
					// color: #20909c;
					font-family: 'Avenir', sans-serif !important;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					padding-top: 4px;
				}

				&.active {
					border-left: 2px solid #185cfe;

					span {
						color: #185cfe;
					}
				}

				.img-holder {
					width: 20px;
					height: 20px;
					margin-right: 13.67px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.img-holderN {
					display: flex;
					gap: .8rem;
					
					// align-items: center;
					// justify-content: flex-end;
					// width: 40px;
					// height: 40px;
					// border-radius: 50%;
					// margin-right: 13.05px;
					@include tableter() {
						gap: .5rem;
					}

					
				}
			}

			.unordered-container {	
				display: flex;
				flex-direction: column;	
				gap: 1.5rem;	
				list-style: none;
				padding-left: 0;

				.lister {
					display: flex;
					// align-self: flex-start;
					// justify-self: end;
					// border: .2rem solid sandybrown;
					
					.page {
						color: #A09FBF;
						font-family: 'Avenir', sans-serif !important;
						font-size: 0.75rem;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.03rem;
						text-transform: uppercase;
					}
				}
			}
		}

		.col-main-content {
			// overflow: auto;
			// height: 40.2rem;
			.main-content {
				padding-left: 41.32px;
				padding-right: 41.63px;
			}
		}
		
	}
}
#modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9999;
    height: 100vh;
    pointer-events: none;
}

#modal-container2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9999;
    height: 100vh;
    pointer-events: none;
}