.Button {
    outline: none;
    background-color: #185CFE;
    color: #fff;

    &.outline {
        background-color: #fff;
        color: #474646;
        border: .0625rem soloid #185CFE;
    }
}